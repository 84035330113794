import React from 'react';
import PropTypes from 'prop-types';
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import SellerReferralCode from "./SellerReferralCode";
import {useAuthStore} from "../../store/useAuthStore";

const GET_REFERRAL_CODE = gql`
  query getReferralCode($sellerId: Int!) {
    getReferralCode(sellerId: $sellerId) {
        code 
    }
  }
`;

/**
 * 셀러 초대코드 가져오는 API 호출 및 렌더링을 담당하는 컴포넌트
 * @param sellerId - 셀러 아이디
 * @param disabled - 추천인 코드를 로드 여부
 * @returns {Element}
 * @constructor
 */
export default function SellerReferralQueryWrapper({sellerId, disabled}) {
    const { isLogin} = useAuthStore();
    const {data, error} = useQuery(GET_REFERRAL_CODE, {
        variables: {
            sellerId : Number(sellerId),
        },
        skip: isLogin || disabled
    });

    if (error) {
        return <></>
    }

    return data && data.getReferralCode.code !== null ? (
        <SellerReferralCode code={data.getReferralCode.code} />
    ) : <></>
}

SellerReferralQueryWrapper.propTypes = {
    sellerId: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired
}
