import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {Helmet} from 'react-helmet';

import Carousel from '../../component/bootstrap/carousel/Carousel';
import Error from '../../component/error/Error';
import Loading from '../../component/loading/Loading';
import SellerPreview, {SELLER_FRAGMENT} from '../../component/sellerPreview/SellerPreview';
import Share from '../../component/share/Share';
import {currencyFormat, timeagoFormat} from '../../util/locale';
import {productSchemaOrg, SCHEMA_CONTEXT} from '../../util/schemaOrg';
import {brandUrl, checkoutUrl, discoverUrl, loginUrl, productUrl, webappURL} from '../../util/url';

import {DISCOVER_TAB} from '../discover/Discover';

import iconShield from './assets/icon_shield.svg';
import ProductOpenGraph from './ProductOpenGraph';
import ProductTwitterCard from './ProductTwitterCard';

import './Product.css';
import {useAuthStore} from "../../store/useAuthStore";
import {logViewItem} from "../../util/analytics";
import toast from "react-hot-toast";
import {convertGender, isWithinInt32Range, saveAfterLoginPath} from "../../util/util";
import InvisibleProduct from "../../component/invisibleProduct/InvisibleProduct";
import SimilarProductQueryWrapper from "../../component/similarProductList/SimilarProductQueryWrapper";
import HorizontalProductsList from "../home/horizontalProductsList/HorizontalProductsList";
import ProductPolicy from "./ProductPolicy";
import StatusErrorProduct from "../../component/statusErrorProduct/StatusErrorProduct";
import SellerReferralQueryWrapper from "../../component/sellerReferralCode/SellerReferralCodeQueryWrapper";
import MountedWrapper from "../../component/mountedWrapper/MountedWrapper";

const SELLING_STATUS = 'selling';

export const PRODUCT_DETAILS_PRELOAD_FRAGMENT = gql`
    fragment ProductDetailsPreloadFragment on ProductNotMine {
        id
        createdAt
        category
        title
        description
        brand
        price
        status
        external_url
        resizedSmallImages
        is_visible
        size
        condition
        discount_rate
        like_count
    }
`;
const SEE_PRODUCT = gql`
    query SeeProductResponse($productId: Int!) {
        seeProductResponse(id: $productId) {
            code
            message
            seeProduct {
                ...ProductDetailsPreloadFragment
                seller {
                    ...SellerFragment
                    id
                    rating
                    review_count
                    reviews {
                        id
                        text
                        rating
                        createdAt
                        user {
                            username
                        }
                    }
                    user {
                        id
                        username
                        nickname
                        bio
                        resizedSmallImage
                        resizedBigImage
                        follower_count
                    }
                }
                resizedBigImages
                gender
                condition
            }
        }
    }
    ${SELLER_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * Product component.
 */
export default function Product({ hostname, userAgent }) {
    // modal detection
    const navigate = useNavigate();
    const { isLogin, user } = useAuthStore();

    // product details
    const {productId: encodedProductId} = useParams();
    const productId = parseInt(encodedProductId, 36);

    if (!isWithinInt32Range(productId)) {
        return <div className="Error alert alert-danger" role="alert">잘못된 접근입니다.</div>
    }

    const {loading, error, data} = useQuery(
        SEE_PRODUCT,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                productId
            }
        }
    );

    const handleOnPurchase = () => {
        if (isLogin) {
            if (user.id === product.seller.id) {
                toast('판매자와 구매자는 동일할 수 없습니다.');
            } else {
                navigate(checkoutUrl(product));
            }
        } else {
            alert('간편 로그인 후 구매 가능합니다.');
            saveAfterLoginPath(checkoutUrl(product));
            navigate(loginUrl());
        }
    }

    // ecommerce analytics
    useEffect(() => {
        if (!loading && data.seeProductResponse.code === 200 && data.seeProductResponse.seeProduct) {
            logViewItem('webapp', data.seeProductResponse.seeProduct);
        }
    }, [loading, data]);

    if (loading && (!data || !data.seeProductResponse.code || !data.seeProductResponse)) {
        // only show the loading indicator when no preload data is available
        return <Loading/>;
    } else if (data.seeProductResponse.code !== 200) {
        return <StatusErrorProduct userAgent={userAgent} message={data.seeProductResponse.message} />
    } else if (error) {
        return <Error error={error}/>;
    } else if (!data.seeProductResponse.seeProduct.is_visible) {
        return <InvisibleProduct />
    }

    // build the UI elements
    const product = data.seeProductResponse.seeProduct;
    const images = (loading ?  [product.resizedSmallImages[0]] : product.resizedBigImages).map((image, index) => (
        <div className="Product-1by1"
             key={index}>
            <div className="Product-1by1-content">
                {/* placeholder visible while loading main images, displaying the already cached low-resolution
                  * thumbnail from the products list */}
                <div className="Product-image-preload"
                     style={{backgroundImage: index === 0 ? `url(${product.resizedSmallImages[0]}` : null}}>
                    {/* high-resolution product images */}
                    <img className="Product-image d-block w-100"
                         alt={`${product.title} 상품이미지${index + 1}`}
                         src={image} />
                </div>
            </div>
        </div>
    ));

    const actions = product.status !== SELLING_STATUS ?
        (
           <button type="button"
                   className="Product-buy btn btn-dark btn-lg"
                   disabled="disabled">
               품절
           </button>
        ) : product.external_url ?
        (
            <a className="Product-buy btn btn-dark btn-lg"
               href={product.external_url}
               rel="noopener noreferrer"
               target="_blank">
                사이트에서 보기
            </a>
        ) :
        (
            <div className="Product-buy btn btn-dark btn-lg" onClick={handleOnPurchase}>
                안전결제
            </div>
        );

    // meta tag
    const metaTitle = `${product.title} | 후루츠패밀리`;

    return (
        <section className={"Product container Product-container"}>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={product.description}/>
                <link rel="canonical" href={webappURL(hostname) + productUrl(product)}/>
                {!loading && (
                    <script
                        type="application/ld+json">{JSON.stringify(productSchemaOrg(product, SCHEMA_CONTEXT))}</script>
                )}
            </Helmet>
            {!loading && (
                <>
                    <ProductOpenGraph product={product} title={metaTitle} hostname={hostname}/>
                    <ProductTwitterCard product={product} title={metaTitle}/>
                </>
            )}

            <div className="Product-row row">
                <div className="Product-col col-lg-7">
                    <div className="Product-carousel">
                        <Carousel key={product.id} autoPlay={false}>
                            {images}
                        </Carousel>
                    </div>
                    {!loading && <SimilarProductQueryWrapper productId={product.id}/>}
                </div>
                <div className="Product-col Product-col--description col-lg-5">
                    <div className="Product-details">
                        {product.brand && (
                            <div>
                                <h2 className="Product-brand"><Link
                                    to={brandUrl(product.brand)}>
                                    {product.brand}
                                </Link></h2>
                                <div className="Product-share">
                                    <Share hostname={hostname}
                                           path={productUrl(product)}
                                           text={`${product.title} on FruitsFamily`}/>
                                </div>
                            </div>
                        )}
                        <h1 className="Product-title">
                            {product.title}
                        </h1>

                        <p className="Product-description">
                            {product.description}
                        </p>

                        <div className="Product-category-date">
                            <div className="Product-category">
                                <span>{convertGender(product.gender)}</span>
                                <span>{' > '}</span>
                                <h3><Link to={discoverUrl(DISCOVER_TAB.PRODUCT, {name: product.category})}>
                                    {product.category}
                                </Link></h3>
                            </div>
                            <div className="Product-date">
                                {timeagoFormat(new Date(product.createdAt))}
                            </div>
                        </div>

                        {!product.external_url && (
                            <div className="Product-safety">
                                <a
                                    href="https://intercom.help/fruits-family-co/articles/3674785"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <img className="Product-safety-shield"
                                         alt="안전거래 표시"
                                         src={iconShield}/>
                                    <span className="Product-safety-text">
                                        {`후루츠 '안전결제'하고 걱정없이 거래하세요`}
                                    </span>
                                </a>
                            </div>
                        )}

                        <h4 className="Product-subtitle">
                            판매자 정보
                        </h4>
                        <div className={`Product-seller ${loading ? 'Product-seller--loading' : ''}`}>
                            {!loading && (
                                <SellerPreview
                                    seller={product.seller}
                                />
                            )}
                        </div>
                    </div>

                    <div className="Product-fixed-Footer container">
                        {!loading && <MountedWrapper>
                            <SellerReferralQueryWrapper sellerId={product.seller.id}
                                                        disabled={product.status !== SELLING_STATUS}/>
                        </MountedWrapper>}
                        <div className="Product-actions">
                            <div className="Product-price-container">
                                {product.discount_rate && <div className="Product-discount-rate font-proxima">
                                    {product.discount_rate}%
                                </div>}
                                <div className="Product-price font-proxima">
                                    {currencyFormat(product.price, 'KRW')}
                                </div>
                            </div>
                            {actions}
                        </div>
                    </div>
                </div>
            </div>

            <ProductPolicy/>

            {!loading && product.brand &&
                <section className="Product-other-products">
                    <h5 className="Product-section-title">
                    {product.brand} 모아 보기
                    </h5>
                    <HorizontalProductsList userAgent={userAgent}
                                            rowClassName="row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5"
                                            isMultiple={true}
                                            variables={{
                                                filter: {
                                                    brand: product.brand,
                                                    categories: [product.category],
                                                    show_only: 'selling'
                                                },
                                                sort: 'NEW',
                                            }}
                    />
                </section>
            }
        </section>
    );
}
Product.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
};
