import React from 'react';
import {NavLink, useLocation, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';

import {brandUrl, webappURL} from '../../util/url';
import Error from '../../component/error/Error';
import Loading from '../../component/loading/Loading';
import {PRODUCT_FRAGMENT} from '../../component/productPreview/ProductPreview';
import ProductsList from '../../component/productsList/ProductsList';

import {CATEGORY_ALL} from '../discover/Discover';
import NotFound from "../notFound/NotFound";
import {PRODUCT_DETAILS_PRELOAD_FRAGMENT} from '../product/Product';
import {META_TAG_TITLE} from "../../constants/constants";

import BrandOpenGraph from './BrandOpenGraph';
import BrandTwitterCard from './BrandTwitterCard';

import './Brand.css';
import PropTypes from 'prop-types';

// GraphQL queries
const GET_BRAND_BY_NAME = gql`
    query getBrandByName($name: String!) {
        getBrandByName(name: $name) {
            id
            name
            name_kr
            description
            resizedSmallLogo
            resizedBigLogo
        }
    }
`;
const BRAND_CATEGORIES = gql`
    query getCategories($brands: [String!]) {
        getCategories(brands: $brands, excludeEmpties: true, limit: 1000)  {
            name
        }
    }
`;
const SEE_PRODUCTS = gql`
    query SeeProducts($filter: ProductFilter!, $offset: Int, $limit: Int, $sort: String, $isSearch: Boolean) {
        searchProducts(filter: $filter, offset: $offset, limit: $limit, sort: $sort, isSearch: $isSearch) {
            ...ProductFragment
            ...ProductDetailsPreloadFragment
            price
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * Category brand component.
 * @param hostname
 */
export default function Brand({hostname}) {
    const {
        brandName: partiallyEncodedBrandName,
        categoryName: partiallyEncodedCategoryName = CATEGORY_ALL.name // default parameter value
    } = useParams();

    //get querystring isSearch
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isSearch = queryParams.get('isSearch');

    // fix for reacter-router partial decoding of uri component parameters
    let brandName;
    try {
        brandName = decodeURIComponent(partiallyEncodedBrandName)
    } catch (error) {
        brandName = partiallyEncodedBrandName;
    }

    // fix for reacter-router partial decoding of uri component parameters
    let categoryName;
    try {
        categoryName = decodeURIComponent(partiallyEncodedCategoryName)
    } catch (error) {
        categoryName = partiallyEncodedCategoryName;
    }

    // fetch categories
    const { loading: brandLoading, error: brandError, data: {getBrandByName: brand} = {} } = useQuery(
        GET_BRAND_BY_NAME,
        {
            variables: {
                name: brandName
            }
        }
    );
    const { loading: categoryLoading, error: categoryError, data: categoryData } = useQuery(
        BRAND_CATEGORIES,
        {
            variables: {
                brands: [brandName]
            }
        }
    );

    if (brandLoading || categoryLoading) {
        return <Loading />;
    } else if (brandError || categoryError) {
        return <Error error={brandError ? brandError : categoryError} />;
    }

    // add client-side pseudo-categories
    const allCategories = categoryData ?
        [CATEGORY_ALL, ...categoryData.getCategories] :
        null;

    // find the selected category matching with the categoryName route parameter
    const selectedCategory = allCategories ?
        allCategories.find(category => categoryName === category.name) :
        null;
    if (allCategories && !selectedCategory) {
        return <NotFound />;
    }

    // build the UI elements
    const categoryItems = allCategories && allCategories.length > 2 ?
        allCategories.map(category => (
            <li className="Brand-filter-item"
                key={category.name}>
                <h3>
                    <NavLink className="Brand-filter-toggle btn btn-outline-dark"
                         to={brandUrl(brand.name, category)} end={true}
                         replace={true}>
                        {category.name}
                    </NavLink>
                </h3>
            </li>
        )) :
        null;

    // meta tag
    const metaTitle = (brand.name_kr ? `${brand.name_kr}(${brand.name})` : brand.name) + ' | ' + META_TAG_TITLE;
    const metaDescription = (brand.description ? `${brand.description.trim()} | ` : '') +
        `${brand.name}의 빈티지 세컨핸드 상품을 후루츠에서 한번에.`;

    return (
        <section className="Brand container">
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href={webappURL(hostname) + brandUrl(brand.name, selectedCategory)} />
                <link rel="alternate" type="application/atom+xml" href={`${brandUrl(brand.name, selectedCategory)}/feed.atom`} />
            </Helmet>
            <BrandOpenGraph brand={brand} selectedCategory={selectedCategory} title={metaTitle} description={metaDescription} hostname={hostname}/>
            <BrandTwitterCard brand={brand} selectedCategory={selectedCategory} title={metaTitle} description={metaDescription} />

            <div className='Brand-nav'>
                <div className="Brand-image-container">
                    {brand.resizedSmallLogo && (
                        <img className="Brand-logo"
                             src={brand.resizedSmallLogo}
                             alt={brand.name_kr ? brand.name_kr : brand.name} />
                    )}
                    <div className="Brand-title-container">
                        <h2 className="Brand-title font-nhaasgrotesk">
                            {brand.name}
                        </h2>
                        {brand.name_kr && (
                            <h1 className="Brand-subtitle font-nhaasgrotesk text-secondary">
                                {brand.name_kr}
                            </h1>
                        )}
                    </div>
                </div>
                {brand.description && (
                    <p className='Brand-description'>
                        {brand.description}
                    </p>
                )}
            </div>

            <div className="Brand-products">
                <ul className="Brand-filter list-unstyled d-flex flex-wrap">
                    {categoryItems}
                </ul>
                <ProductsList itemsProperty="searchProducts"
                              query={SEE_PRODUCTS}
                              variables={{
                                  filter: {
                                      brand: brand.name,
                                      category: selectedCategory === CATEGORY_ALL ? undefined : selectedCategory.name
                                  },
                                  isSearch : isSearch !== null,
                                  sort: 'POPULAR'
                              }} />
            </div>
        </section>
    );
}
Brand.propTypes = {
    hostname: PropTypes.string.isRequired,
};
